<template>
  <div class="vcode-login">
    <div class="vcode-login-view">
      <el-form ref="form" :model="form" :rules="rules">
        <el-form-item prop="mobile">
          <p class="vcode-login-view-title">{{ $t('mobile') }}</p>
          <div class="vcode-login-view-content vcode-login-view-mobile">
            <div class="codice-paese pointer">
              <el-dropdown @command="changePhoneCode">
          <span class="el-dropdown-link">
            {{ phoneCodeValue }}
          </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item
                      v-for="item in phoneCodeList"
                      :key="item.id"
                      :command="item"
                  >
                    {{ item.country }}+{{ item.telephone_code }}
                  </el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </div>
            <p class="line"></p>
            <el-input v-model="form.mobile" :placeholder="$t('placeholder')+$t('mobile')"></el-input>
          </div>
        </el-form-item>
        <el-form-item prop="vcode">
          <p class="vcode-login-view-title title-top">{{ $t('vCodeText') }}</p>
          <div class="vcode-login-view-content vcode-login-view-vcode">
            <el-input v-model="form.code" :placeholder="$t('placeholder')+$t('vCodeText')"></el-input>
            <el-button type="primary" @click="changeCode" :disabled="vCodeLoading">{{ vCodeBtnText }}</el-button>
          </div>
        </el-form-item>
        <el-button class="submit-login" type="primary" @click="submitForm" :loading="loading">{{
            $t('login')
          }}
        </el-button>
        <p class="login-option">
          <span class="pointer" @click="forgetPassword">{{ $t('forgotPassword') }}</span> |
          <span @click="changRegister" class="pointer">{{ $t('signUpNow') }}</span></p>
      </el-form>
    </div>
    <!-- 登陆成功弹窗 -->
    <LoginSucceed ref="popupLogin" @confirm="confirmLogin"></LoginSucceed>
  </div>
</template>

<script>
import {getTelephoneCode} from "@/api/Common";
import {getToken, sendVCode} from "@/utils/common";
import {mobileLogin} from "@/api/User";
import {setUserInfo} from "@/utils/common/user";
import LoginSucceed from "@/components/popup/LoginSucceed.vue"

export default {
  props: {},
  data() {
    return {
      phoneCodeList: '',
      phoneCodeValue: this.$t("countryChina") + '+86',
      telephoneCode: '',
      form: {
        mobile: '',
        code: ''
      },
      rules: {
        mobile: [
          {required: true, message: this.$t('placeholder') + this.$t('mobile'), trigger: 'blur'}
        ],
        code: [
          {required: true, message: this.$t('placeholder') + this.$t('vCodeText'), trigger: 'blur'}
        ]
      },
      loading: false,
      vCodeLoading: false,
      vCodeCount: 60,
      vCodeBtnText: this.$t('testGetCode'),
      userInfo: {}
    }
  },
  components: {
    LoginSucceed
  },
  computed: {},
  created() {
	  // this.$store.state.playShow = false
	  // let DemoseaList = {}
	  // this.$set(DemoseaList, 'playStatus', true)
	  // this.$store.commit('changeMusicInfo', DemoseaList);
  },
  mounted() {
    if (getToken()) {
      this.$router.push({
        path: '/recommend'
      })
    }
    this.initTelephoneCode()
  },
  watch: {},
  methods: {
    sendVCode,
    setUserInfo,
    changRegister() {
      this.$router.push({
        path: '/register/mobileregister'
      })
    },
    forgetPassword() {
      this.$router.push({
        path: '/reset/resetpassword'
      })
    },
    // 获取国地区手机编码
    initTelephoneCode() {
      getTelephoneCode().then((res) => {
        console.log('获取国地区手机编码成功:', res)
        this.phoneCodeList = res.data.list
      }).catch(err => {
        console.log('获取国地区手机编码失败:', err)
      })
    },
    changePhoneCode(command) {
      this.phoneCodeValue = `${command.country}+${command.telephone_code}`
      this.telephoneCode = command.telephone_code
    },
    changeCode() {
      if (this.form.mobile.trim()) {
        this.sendVCode(this.telephoneCode, this.form.mobile)
        this.countDown()
      } else {
        this.form.mobile = ''
        this.$message.error(this.$t('placeholder') + this.$t('mobile'))
      }
    },
    countDown() {
      let inter = setInterval(() => {
        if (this.vCodeCount == 0) {
          clearInterval(inter)
          this.vCodeLoading = false
          this.vCodeCount = 60
          this.vCodeBtnText = this.$t('testGetCode')
        } else {
          this.vCodeLoading = true
          this.vCodeCount--
          this.vCodeBtnText = `${this.vCodeCount}${this.$t('tryAgainInSeconds')}`
        }
      }, 1000)
    },
    submitForm() {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          this.loading = true
          mobileLogin(this.form).then(res => {
            this.loading = false
            console.log('登录成功:', res)
            // this.$message({
            //   message: res.msg,
            //   type: 'success'
            // });
			let first_login = res.data.first_login
			if(first_login != 0){
				this.$refs.popupLogin.open()
			}else {
				this.setUserInfo(this.userInfo)
				this.$router.push({
					  path: '/recommend'
					})
			}
            // this.$refs.popupLogin.open()
            this.$refs['form'].resetFields();
            this.userInfo = res.data
            // this.$router.push({
            //   path: '/recommend'
            // })
          }).catch(err => {
            this.loading = false
            console.log('登录失败:', err)
          })
        } else {
          return false;
        }
      });
    },
    confirmLogin() {
      this.setUserInfo(this.userInfo)
      this.$router.push({
        path: '/recommend'
      })
    }
  }
}
</script>

<style scoped lang='less'>
.vcode-login {
  margin-top: 23px;
  padding: 60px;
  background-color: @recommendMusicianListBGColor;
  border-radius: 6px;

  &-view {
    width: 300px;
    margin: 0 auto;

    ::v-deep .el-form-item {
      margin-bottom: 0;
    }

    &-title {
      height: 20px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: @buttonTextColor;
      line-height: 20px;
    }

    .title-top {
      margin-top: 40px;
    }

    &-content {
      width: 300px;
      height: 42px;
      margin-top: 10px;
      background-color: @loginInputBgColor;
      border-radius: 4px;

      .codice-paese {
        line-height: 42px;
        padding-left: 12px;
        width: 22%;

        ::v-deep .el-dropdown {
          font-size: 12px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: @buttonTextColor;
        }
      }

      .line {
        width: 1px;
        height: 22px;
        background: @buttonTextColor;
        margin: 10px;
      }

      ::v-deep .el-input__inner {
        border: none;
        line-height: 42px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: @buttonTextColor;
        padding: 0;
        background-color: rgba(255, 255, 255, 0);
      }
    }

    &-mobile {
      display: flex;
    }

    &-vcode {
      display: flex;
      justify-content: space-between;

      ::v-deep .el-input__inner {
        padding-left: 12px;
      }
    }

    .submit-login {
      width: 100%;
      margin-top: 40px;
    }

    .login-option {
      font-size: 16px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: @buttonTextColor;
      line-height: 22px;
      text-align: center;
      margin-top: 24px;
    }

    ::v-deep .el-button--primary {
      background-color: #3370FF !important;
      border-color: #3370FF !important;

      &:hover {
        opacity: .8;
      }
    }
  }
}
</style>
